import React from "react";
import './HomeVideo.scss'; 
import BusyRestaurant from '../../../Assets/ocean.mp4';

import { Container, Row, Col } from "react-bootstrap";
import packageSample from "../../../Assets/home-sheets.png";
import Tilt from "react-parallax-tilt";
import Type from "../Type";



function HomeVideo() {

  return (
    <div className="video-wrapper">
      <div className="video-container">
        <video className="full-width-video" autoPlay muted loop playsInline>
          <source
            src={BusyRestaurant}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <Container fluid className="home-section" id="home">
          <Container className="home-content">
            <Row className="home-header">
              <Col md={7}>
                <h1 className="heading-name">
                  We are
                  <strong className="main-name"> Klean Leaf</strong>
                  <span className="wave" role="img" aria-labelledby="wave" style={{ paddingLeft: 15 }}>
                    👋🏻
                  </span>
                </h1>
                <h1 className="heading-subtitle">
                  Laundry Detergent Sheets Manufacturer
                </h1>

                <h2 className="heading-name we-offer">We Offer:</h2>
                <div className="typing-font">
                  <Type />
                </div>
              </Col>

              <Col md={5} style={{ paddingBottom: 80 }}>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </div>
  );
}
export default HomeVideo;
