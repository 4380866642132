import React from 'react';
import { Link } from 'react-router-dom';
import post1 from '../BlogPostsContent/BlogPost1';
import post2 from '../BlogPostsContent/BlogPost2';
import post3 from '../BlogPostsContent/BlogPost3';
import post4 from '../BlogPostsContent/BlogPost4';
import post5 from '../BlogPostsContent/BlogPost5';
import post6 from '../BlogPostsContent/BlogPost6';
import post7 from '../BlogPostsContent/BlogPost7';
import post8 from '../BlogPostsContent/BlogPost8';
import './BlogList.scss'; 


const blogPosts = [post1, post2, post3, post4, post5, post6, post7, post8]; // Add all your posts to this array
// Sort posts by date, assuming date is in 'YYYY-MM-DD' format
blogPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

const BlogList = () => {
  const latestPost = blogPosts[0]; // The first post is the latest one
  const otherPosts = blogPosts.slice(1); // All other posts

  return (
    <div className="blog-list">
        <div className="blog-list-title">
          <h1>Laundry Detergent Sheets</h1>
          <h2 className="blog-page-title">
            Blogs Page
          </h2>
        </div>
      {/* Render the latest post */}
      <div className="blog-latest">
        <img src={latestPost.imageUrl1} alt={latestPost.title} className="blog-image" />
        <div className="blog-details">
        <Link to={`/blogs/${latestPost.id}`} className="blog-title">{latestPost.title}</Link>
          <p className="blog-date">{latestPost.date}</p>
          <p className="blog-summary-text">{latestPost.summary}</p>
          <Link to={`/blogs/${latestPost.id}`} className="blog-read-more">Read Article</Link>
        </div>
      </div>
      {/* Render other posts */}
      <div className="blog-cards">
        {otherPosts.map((post) => (
          <div key={post.id} className="blog-card">
            <img src={post.imageUrl1} alt={post.title} className="blog-image" />
            <div className="blog-details">
              <Link to={`/blogs/${post.id}`} className="blog-title">{post.title}</Link>
              <p className="blog-date">{post.date}</p>
              <p className="blog-summary-text">{post.summary}</p>
              <Link to={`/blogs/${post.id}`} className="blog-read-more">Read Article</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
