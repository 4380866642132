import React from "react";

import HomeVideo from "./HomeVideo/HomeVideo";
import Home2 from "./Home2";


function Home() {
  return (
    <>
    <HomeVideo />
    <section>
      <Home2 />
    </section>
    </>
  );
}

export default Home;
