import React from "react";
import Card from "react-bootstrap/Card";

function AboutCardOne() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
      </Card.Body>
    </Card>
  );
}

export default AboutCardOne;
