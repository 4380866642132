import React from "react";
import { Col, Row } from "react-bootstrap";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={6} md={3} className="tech-icons">
        No Mess
      </Col>
      <Col xs={5} md={2} className="tech-icons">
        Customization
      </Col>
      <Col xs={5} md={2} className="tech-icons">
        Inspection
      </Col>
      <Col xs={5} md={2} className="tech-icons">
        Sustainable
      </Col>
      <Col xs={5} md={2} className="tech-icons">
        Performant
      </Col>
      <Col xs={8} md={3} className="tech-icons">
        Plastic Free
      </Col>
      <Col xs={6} md={2} className="tech-icons">
        Innovative
      </Col>
      <Col xs={5} md={2} className="tech-icons">
        Packaging
      </Col>
      <Col xs={5} md={3} className="tech-icons">
        On Time
      </Col>
      <Col xs={7} md={3} className="tech-icons">
        Quick Dissolve
      </Col>
      <Col xs={8} md={2} className="tech-icons">
        Professional
      </Col>
      <Col xs={7} md={3} className="tech-icons">
        Bio-degradable
      </Col>
      <Col xs={3} md={2} className="tech-icons">
        Compact
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        Experienced
      </Col>
      <Col xs={5} md={2} className="tech-icons">
        Effeciency
      </Col>
    </Row>
  );
}

export default Techstack;
