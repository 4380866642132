import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FiPhoneCall } from "react-icons/fi";
import {
  MdOutlineLocalLaundryService,
  MdOutlineDesignServices,
} from "react-icons/md";
import Rellax from 'rellax';
import { TbTruckDelivery } from "react-icons/tb";
import { BsChatRightHeart } from "react-icons/bs";
import { BiPackage, BiTimer, BiCheckCircle } from "react-icons/bi";
import numberOne from "../../Assets/numbers/number-1.png";
import numberTwo from "../../Assets/numbers/number-2.png";
import numberThree from "../../Assets/numbers/number-3.png";
import numberFour from "../../Assets/numbers/number-4.png";
import numberFive from "../../Assets/numbers/number-5.png";
import numberSix from "../../Assets/numbers/number-6.png";
import numberSeven from "../../Assets/numbers/number-7.png";
import bubbles1 from "../../Assets/bubbles1.png";
import splash from "../../Assets/splash.png";
import laundry from "../../Assets/laundry.png";
import hanger from "../../Assets/hanger1.png";


function Service() {
  useEffect(() => {
    const rellax = new Rellax('.rellax');
    return () => {
      rellax.destroy();
    };
  }, []);
  
  return (
    <div className="service">
      <div className="background-wrapper">
        <img src={bubbles1} alt="bubbles" className="rellax background1" data-rellax-speed="7" />
        <img src={splash} alt="splash" className="rellax background2" data-rellax-speed="-4" />
        <img src={hanger} alt="hanger" className="rellax background3" data-rellax-speed="1" />
        <img src={laundry} alt="laundry" className="rellax background4" data-rellax-speed="2" />
      </div>
      <Container fluid className="service-page">
        <Container>
          <Row style={{ textAlign: "center" }}>
            <h1>Laundry Detergent Sheets</h1>
            <h2 className="service-title">
              Our Service
              <p>One stop service</p>
            </h2>
          </Row>

          <Row className="work-flow-line">
            <Col md={5} xs={12} className="work-flow-left">
              <img src={numberOne} alt="number" className="img-fluid" />
              <span className="work-flow-title">Free Samples</span>
            </Col>

            <Col md={7}>
              <MdOutlineLocalLaundryService className="work-flow-icon" />
              Reach out to us via phone or email to request a complimentary
              sample. We are confident that once you experience its
              effectiveness, you will be delighted with the results.
            </Col>
          </Row>

          <Row className="work-flow-line">
            <Col md={5} xs={12} className="work-flow-left">
              <img src={numberTwo} alt="number" className="img-fluid" />
              <span className="work-flow-title">Professional Consulting</span>
            </Col>
            <Col md={7}>
              <BsChatRightHeart className="work-flow-icon" />
              If you find our product aligns with your needs and preferences,
              what's the next step? One of our dedicated team members will
              promptly contact you to initiate a comprehensive consultation. Our
              services encompass an all-encompassing solution, encompassing
              manufacturing, packaging, liquidation strategies, logistics, and
              direct delivery to your warehouse.
            </Col>
          </Row>

          <Row className="work-flow-line">
            <Col md={5} xs={12} className="work-flow-left">
              <img src={numberThree} alt="number" className="img-fluid" />
              <span className="work-flow-title">Customized Design</span>
            </Col>
            <Col md={7}>
              <MdOutlineDesignServices className="work-flow-icon" />
              Be it fine-tuning the formulation, dimensions, or scents, our
              expertise and adaptable methodology ensure your private-label
              detergent sheets will be meticulously tailored to your precise
              demands, providing you with peace of mind.
            </Col>
          </Row>

          <Row className="work-flow-line">
            <Col md={5} xs={12} className="work-flow-left">
              <img src={numberFour} alt="number" className="img-fluid" />
              <span className="work-flow-title">Packaging Design</span>
            </Col>
            <Col md={7}>
              <BiPackage className="work-flow-icon" />
              In support of a successful product launch into the market, we
              extend our expertise in packaging consultation and offer guidance
              that aligns seamlessly with your budget, brand identity, and
              market prerequisites.
            </Col>
          </Row>

          <Row className="work-flow-line">
            <Col md={5} xs={12} className="work-flow-left">
              <img src={numberFive} alt="number" className="img-fluid" />
              <span className="work-flow-title">On Time Production</span>
            </Col>
            <Col md={7}>
              <BiTimer className="work-flow-icon" />
              Upon client approval of the bulk sample, our production process
              typically culminates within a 10-day timeframe.
            </Col>
          </Row>

          <Row className="work-flow-line">
            <Col md={5} xs={12} className="work-flow-left">
              <img src={numberSix} alt="number" className="img-fluid" />
              <span className="work-flow-title">Inspection Support</span>
            </Col>
            <Col md={7}>
              <BiCheckCircle className="work-flow-icon" />
              Each laundry detergent sheet undergoes a meticulous in-house
              inspection as an integral part of our delivery process. Moreover,
              we extend our commitment to transparency by providing
              comprehensive goods inspection support, offering detailed photos
              and videos that afford you the opportunity to scrutinize your
              products as if you were present at our manufacturing facility. We
              wholeheartedly welcome both on-site visits and third-party
              inspections to further ensure the highest level of quality
              assurance.
            </Col>
          </Row>

          <Row className="work-flow-line">
            <Col md={5} xs={12} className="work-flow-left">
              <img src={numberSeven} alt="number" className="img-fluid" />
              <span className="work-flow-title">Delivery</span>
            </Col>
            <Col md={7}>
              <TbTruckDelivery className="work-flow-icon" />
              We offer an all-encompassing, turnkey service that encompasses the
              management of shipping logistics and custom clearance on your
              behalf.
            </Col>
          </Row>

          <Row style={{ justifyContent: "center", position: "relative" }}>
            <a
              href="tel:614-906-5129"
              className="btn call-us dark-bg rounded-pill py-md-3 px-md-5 mt-4"
            >
              <FiPhoneCall />
              &nbsp;&nbsp;Call Us For More Info
            </a>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default Service;
