import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";

function Footer() {

  return (
    <Container fluid className="footer">
      <Row>
        <Col md="3" className="footer-copywright">
        <a href="/" className="design"><h3>Designed by Klean Leaf</h3></a>
        </Col>
        <Col md="3" className="footer-copywright">
          <a
            href="mailto:hello@kleanleaf.us"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>hello@kleanleaf.us</h3>
          </a>
        </Col>
        <Col md="3" className="footer-copywright">
          <a
            href="mailto:kleanleaf@gmail.com"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>kleanleaf@gmail.com</h3>
          </a>
        </Col>
        <Col md="3" className="footer-copywright">
          <a href="tel:614-906-5129" style={{ textDecoration: "none" }}>
            <h3>614.906.5129</h3>
          </a>
        </Col>
        <Col md="3" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://twitter.com/kleanleaf"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.facebook.com/kleanleaf/"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillFacebook />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/kleanleaf"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
